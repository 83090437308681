import React, {useEffect, useReducer} from 'react';
import {useDispatch, useStore} from 'react-redux';
import {renderRoutes} from 'react-router-config';
import {object} from 'prop-types';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {Helmet} from 'react-helmet-async';
import {fetchCupMetaData} from 'app/routes/cup/CupPageActions';
import {CupPageReducer} from 'app/routes/cup/CupPageReducer';
import {TabBar} from 'app/components/tab/TabBar';
import {useParams} from 'react-router';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {createCompetitionPathFromParams, generateBasicCompetitionLink} from 'app/helpers/actionsHelpers';
import {ErrorHandler} from 'app/components/error/ErrorHandler';
import {updateLastCompetitions} from 'app/helpers/lastItemsHelpers';

const CupPage = ({route}) => {
  const {restoredData} = useRestoreData('CupPage');
  const {cupSlug, seasonSlug} = useParams();
  const initialState = restoredData ?? {};
  const [state, dispatch] = useReducer(CupPageReducer, initialState);
  const dispatchRedux = useDispatch();
  const {getState} = useStore();

  const loadData = () => fetchCupMetaData(cupSlug, seasonSlug, dispatchRedux, getState)(dispatch, state);
  useFetchData(state, loadData, 'CupPage', true);

  const defaultTitle = seasonSlug && state.season ? `${state.name} ${state.season.name}` : state.name;
  const districtName = state.district?.name ?? '';
  const titleTemplate = `%s${defaultTitle} - ${districtName}${TITLE_POSTFIX}`;
  const defaultMeta = `Alle Spiele, Fußball-News, Ergebnisse, Tabellen, Liveticker und die Elf der Woche des Turniers ${defaultTitle} - Kreis ${districtName}.`;

  useEffect(() => {
    if (state.slug && state.name && state.slug === cupSlug && state.active) {
      const competitionData = {
        slug: state.slug,
        name: state.middleName,
        image: state.image,
        isLeague: false,
      };
      updateLastCompetitions(competitionData);
    }
  }, [state.slug, state.name, state.slug, state.active, cupSlug]);

  const baseUrl = Object.keys(state).length
    ? generateBasicCompetitionLink(state)
    : createCompetitionPathFromParams('cup', cupSlug, route.season ? seasonSlug : null);
  const TabNames = [
    {
      name: 'News',
      url: baseUrl,
      hasSubUrl: true,
    },
    {
      name: 'Spiele',
      url: `${baseUrl}/matches`,
    },
    {
      name: 'Rankings',
      url: `${baseUrl}/rankings`,
    },
    {
      name: 'Chronik',
      url: `/cup/${cupSlug}/history`,
    },
  ];

  const cupActive = state.active;
  const robots = cupActive ? 'index, follow' : 'noindex, follow';
  const meta = [
    {
      name: 'robots',
      content: robots,
    },
  ];

  return (
    <>
      <Helmet
        defaultTitle={defaultTitle + ' - ' + districtName + TITLE_POSTFIX}
        titleTemplate={titleTemplate}
        meta={meta}
      />
      <TabBar tabs={TabNames} />
      <ErrorHandler subroute={true}>
        {renderRoutes(route.routes, {
          defaultTitle,
          districtName,
          defaultMeta,
          cupActive,
          cupId: state.id,
          competitionSeasonId: state.competitionSeasonId,
          cupInfo: state.info,
          baseUrl,
          dispatchRedux,
        })}
      </ErrorHandler>
    </>
  );
};

CupPage.propTypes = {
  route: object.isRequired,
};

export default CupPage;
